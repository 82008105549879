import React, { useState, useEffect } from "react";
import Sellernavbar from "./Sellernavbar";
import Sellerpagination from "./sellerpagination";
import axios from "axios";
import Scrolltotopbtn from "../Scrolltotopbutton";
import Footer from "../footer";
import {  useData } from "../CartContext";


export default function Shipments() {
  // eslint-disable-next-line no-unused-vars
  const [products, setProducts] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [viewRowIndex, setViewRowIndex] = useState(null);
  const [shippingProducts, setShippingProducts] = useState([]);
  const [checkedShipments, setCheckedShipments] = useState([]); // List of checked shipments and their statuses
  const [canShip, setCanShip] = useState(false); // To enable the "shipped" button
  const [canDeliver, setCanDeliver] = useState(false); // To enable the "delivered" button
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [guestShippingAddresses, setGuestShippingAddresses] = useState([]);
  const [guestBillingAddresses, setGuestBillingAddresses] = useState([]);
  const [modalData, setModalData] = useState(null); // New state to handle modal data

  const { user } = useData();


  useEffect(() => {
    setCurrentPage(1);
    setViewRowIndex(null);
  }, [pageSize]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/shipmentjoin`)
      .then((result) => {
        console.log(result)
        setShippingProducts(
          result.data.filter(
            (item) => item.seller_id.toString() === sessionStorage.getItem("user-token")
          )
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fetchShippingAddresses = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/saveShippingAddress`
        );
        if (res.data !== "Fail" && res.data !== "Error") {
          setShippingAddresses(res.data);
        }
      } catch (error) {
        console.log("Error fetching addresses:", error);
      }
    };

    fetchShippingAddresses();


    const fetchBillingAddresses = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/saveBillingAddress`
        );
        if (res.data !== "Fail" && res.data !== "Error") {
          setBillingAddresses(res.data);
        }
      } catch (error) {
        console.log("Error fetching addresses:", error);
      }
    };
    fetchBillingAddresses();


    const fetchGuestShippingAddresses = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/guestShippingAddresss`
        );
        if (res.data !== "Fail" && res.data !== "Error") {
          setGuestShippingAddresses(res.data);
        }
      } catch (error) {
        console.log("Error fetching addresses:", error);
      }
    };

    fetchGuestShippingAddresses();

    const fetchGuestBillingAddresses = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/guestBillingAddress`
        );
        if (res.data !== "Fail" && res.data !== "Error") {
          setGuestBillingAddresses(res.data);
        }
      } catch (error) {
        console.log("Error fetching addresses:", error);
      }
    };
    fetchGuestBillingAddresses();





  }, [shippingAddresses,billingAddresses,guestShippingAddresses,guestBillingAddresses]);
  useEffect(() => {
    // Determine if the shipment can be shipped or delivered based on its status
    const shipment = shippingProducts.find((item) =>
      checkedShipments.includes(item.shipment_id)
    );
    if (shipment) {
      setCanShip(!shipment.shipped_date);
      setCanDeliver(shipment.shipped_date && !shipment.delivered_date);
    }
  }, [checkedShipments, shippingProducts]);

  const handleOrder = (actionType) => {
    if (checkedShipments.length === 0) {
      console.log("No shipment selected.");
      return;
    }

    const updateRequests = checkedShipments.map((shipmentId) => {
      let updateData = { shipment_id: shipmentId };

      if (actionType === "delivered") {
        updateData.delivered_date = new Date().toLocaleDateString("fr-CA");
      } else if (actionType === "shipped") {
        updateData.shipped_date = new Date().toLocaleDateString("fr-CA");
      }

      return axios.post(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/updateOrder`, updateData);
    });

    Promise.all(updateRequests)
      .then(() => {
        console.log("Orders updated successfully");
        // Update the state or reload data as needed
        setShippingProducts((prev) =>
          prev.map((item) =>
            checkedShipments.includes(item.shipment_id)
              ? { ...item, ...(actionType === "delivered" ? { delivered_date: new Date().toLocaleDateString("fr-CA") } : { shipped_date: new Date().toLocaleDateString("fr-CA") }) }
              : item
          )
        );
        setCheckedShipments([]); // Clear checked shipments
        setCanShip(false); // Reset buttons
        setCanDeliver(false); // Reset buttons
      })
      .catch((err) => console.log("Error updating orders:", err));
  };


  const handleChecked = (e) => {
    const shipmentId = e.target.name;
    const isChecked = e.target.checked;

    if (isChecked) {
      setCheckedShipments((prev) => [...prev, shipmentId]);
    } else {
      setCheckedShipments((prev) => prev.filter((id) => id !== shipmentId));
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const tableData = shippingProducts.slice(startIndex, endIndex);

  const handleViewAddress = (buyerEmail, buyerID) => {
    if(buyerID){
      const shippingAddress = shippingAddresses.find(item => item.user_id === buyerID);
      const billingAddress = billingAddresses.find(item => item.user_id === buyerID);
      setModalData({shippingAddress, billingAddress });
    }
    else if(buyerEmail){
      const shippingAddress = guestShippingAddresses.find(item => item.email === buyerEmail);
      const billingAddress = guestBillingAddresses.find(item => item.email === buyerEmail);
      setModalData({shippingAddress, billingAddress });
    }
  };

 
  return (
    <div className="">
      <Sellernavbar />
      <div className="d-md-flex">
        {/* <div className="col-md-2 selleraccordion">
          <Sellermenu />
        </div> */}
        <div className="container">
          <div className="fullscreen2">
            <main>
            <div className="text-center p-3">
              <h6> <i><span className="" style={{color:"blue" , fontSize:"25px"}}>{user.shopname && user.shopname.length>0 ? (user.shopname) : (user.firstname +" "+ user.lastname)}</span></i> Store</h6>
              </div>
              <div className="d-md-flex justify-content-between mt-3">
               <div className=" ps-md-4">
               <h1 style={{ fontSize: "28px" }}>Shipments</h1>
               </div>
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-info"
                    onClick={() => handleOrder("shipped")}
                    disabled={!canShip || checkedShipments.length === 0}
                  >
                    <i className="bi bi-truck"></i> Set as shipped(selected)
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => handleOrder("delivered")}
                    disabled={!canDeliver || checkedShipments.length === 0}
                  >
                    <i className="bi bi-check2-circle"></i> Set as delivered(selected)
                  </button>
                </div>
              </div>

              <div className=" m-md-3 rounded">
                <div className="table-responsive p-md-3 mt-4">
                  <table
                    id="dynamic-table"
                    className="table table-striped table-bordered table-hover dataTable no-footer"
                    role="grid"
                    aria-describedby="dynamic-table_info"
                  >
                    <thead className="">
                      <tr role="row">
                        <th className="sorting p-3" rowSpan="1" colSpan="1">
                          <label className="pos-rel">
                            <span className="lbl"></span>
                          </label>
                        </th>
                        <th className="sorting p-3" tabIndex="0" aria-controls="dynamic-table" rowSpan="1" colSpan="1">
                          Picture
                        </th>
                        <th className="sorting p-3" tabIndex="0" aria-controls="dynamic-table" rowSpan="1" colSpan="1">
                          Shipment#
                        </th>
                        <th className="sorting p-3" tabIndex="0" aria-controls="dynamic-table" rowSpan="1" colSpan="1">
                          Order#
                        </th>
                        <th className="hidden-480 sorting p-3" tabIndex="0" aria-controls="dynamic-table" rowSpan="1" colSpan="1">
                          Date Ordered
                        </th>
                        <th className="hidden-480 sorting p-3" tabIndex="0" aria-controls="dynamic-table" rowSpan="1" colSpan="1">
                          Date Shipped
                        </th>
                        <th className="hidden-480 sorting p-3" rowSpan="1" colSpan="1">
                          Date Delivered
                        </th>
                        <th className="sorting p-3">
                        View Address
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr role="row" key={index} className="">
                          <th className="sorting p-3" rowSpan="1" colSpan="1">
                            <label className="pos-rel">
                              <input
                                type="checkbox"
                                name={item.shipment_id}
                                className="ace"
                                checked={checkedShipments.includes(item.shipment_id)}
                                onChange={handleChecked}
                              />
                              <span className="lbl"></span>
                            </label>
                          </th>
                          <td style={{ width: "100px", height: "100px" }}>
                            <img
                              src={`${JSON.parse(item.image)[0]}`}
                              alt="Shipment product"
                              style={{ maxWidth: "100%", height: "100px", objectFit: "contain" }}
                            />
                          </td>
                          <td>{item.shipment_id}</td>
                          <td>{item.order_id}</td>
                          <td>{item.ordered_date && item.ordered_date.slice(0, 10)}</td>
                          <td>{item.shipped_date && item.shipped_date.slice(0, 10)}</td>
                          <td>{item.delivered_date && item.delivered_date.slice(0, 10)}</td>
                          <td>
                          <button
                            className="btn btn-primary"
                           type="button"
                              data-bs-toggle="modal"
                            data-bs-target="#addressModal"
                            onClick={() => handleViewAddress(item.customer_email ? item.customer_email : undefined, item.buyer_id)}
                          >
                            View Address
                          </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <Sellerpagination
                  stateData={shippingProducts}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setViewRowIndex={setViewRowIndex}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </main>
            {/* <Sellerfooter /> */}
             <div className="modal fade" id="addressModal" tabIndex="-1" aria-labelledby="addressModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Address Details</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            {/* Shipping Address Section */}
            <div className="col-md-6">
              <h5>Shipping Address</h5>
              {modalData && modalData.shippingAddress ? (
                <div>
                  <p><strong>Name:</strong> {modalData.shippingAddress.firstname + " " + modalData.shippingAddress.lastname}</p>
                  <p><strong>Street:</strong> {modalData.shippingAddress.address1}</p>
                  <p><strong>City:</strong> {modalData.shippingAddress.city}</p>
                  <p><strong>State:</strong> {modalData.shippingAddress.state}</p>
                  <p><strong>Zip Code:</strong> {modalData.shippingAddress.pincode}</p>
                  <p><strong>Country:</strong> {modalData.shippingAddress.country}</p>
                </div>
              ) : (
                <p>No shipping address available</p>
              )}
            </div>

            {/* Billing Address Section */}
            <div className="col-md-6">
              <h5>Billing Address</h5>
              {modalData && modalData.billingAddress ? (
                <div>
                  <p><strong>Name:</strong> {modalData.billingAddress.firstname + " " + modalData.billingAddress.lastname}</p>
                  <p><strong>Street:</strong> {modalData.billingAddress.address1}</p>
                  <p><strong>City:</strong> {modalData.billingAddress.city}</p>
                  <p><strong>State:</strong> {modalData.billingAddress.state}</p>
                  <p><strong>Zip Code:</strong> {modalData.billingAddress.pincode}</p>
                  <p><strong>Country:</strong> {modalData.billingAddress.country}</p>
                </div>
              ) : (
                <p>No billing address available</p>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary"  data-bs-dismiss="modal" aria-label="Close" >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>


            <Scrolltotopbtn />
          </div>
        </div>
        
      </div>
      <Footer/>
    </div>
  );
}
