import React from "react";
import MyNavbar from "./navbar";
import Footer from "./footer";
import Scrolltotopbtn from "./Scrolltotopbutton";
import { Link } from "react-router-dom";

export default function Aboutus() {
  return (
    <div className="fullscreen">
      <MyNavbar />
      <main>
        <img
          src="https://cohereone.com/wp-content/uploads/2020/06/AboutUs_3.1_1950x500-1.jpg"
          alt="aboutus"
          width="100%"
          height="300px"
          style={{
            objectFit: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></img>
        <section className="p-2 m-md-5">
          <p className="fs-6">
            <b>Welcome to The Resale Bazaar</b>, your ultimate destination for both
            preloved and brand-new fashion. We believe style should be
            accessible, affordable, and sustainable, which is why we offer a
            carefully curated collection of high-quality secondhand pieces
            alongside trendy new arrivals.
          </p>
          <p>
            At The Resale Bazaar, we bring you a diverse selection of clothing
            to suit every occasion. Whether you're looking for budget-friendly
            fashion, exclusive designer pieces, or brand-new wardrobe staples,
            we’ve got something for everyone. Our platform makes buying and
            selling effortless, allowing you to refresh your closet while making
            mindful fashion choices.
          </p>
          <p>
            By shopping with us, you're not just upgrading your wardrobe—you’re
            contributing to a more sustainable fashion ecosystem. Choosing
            preloved extends the life of clothing, while our new collections
            provide fresh styles at unbeatable prices.
          </p>
          <p>
            Thank you for visiting The Resale Bazaar! For any questions,
            feedback, or grievances, please email us at &nbsp;
            <Link
              to="mailto:theresalebazaar@gmail.com"
              className="text-decoration-none"
            >
              theresalebazaar@gmail.com
            </Link>
            &nbsp;with the necessary details. We look forward to hearing from
            you!
          </p>
        </section>
      </main>
      <Footer />
      <Scrolltotopbtn />
    </div>
  );
}
