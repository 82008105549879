import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart, useData } from "./CartContext";
import axios from "axios";
import Wishlistemptyimg from "../images/wishlistemptyimg.png";
import RBLogo from "../images/ResaleLogo.png";
import SearchBar from "./Searchbar";
import SideOffcanvas from "./SideOffcanvas";
// import { useAuth } from "../AuthContext";
import { googleLogout } from "@react-oauth/google";

const MyNavbar = () => {
  const [products, setProducts] = useState([]);
  // const [userId, setUserId] = useState(null);
  const { user } = useData();
  const {selectedCountry}= useCart()
  const [isRotated, setIsRotated] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  // const { setIsAuthenticated } = useAuth();

  const navigate = useNavigate();

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    navigate("/search", { state: { termToSearch: searchTerm } });
  };
  // console.log(data.length);
  const {
    cartItems,
    calculateTotalPrice,
    setCartItems,
    wishItems,
    setWishItems,
    removeFromWishlist,
    moveFromWishlistToCart,
    selectedWishlistItems,
    handleCheckboxChange,
    isLoggedIn,
    setIsLoggedIn,
    guest_product,
    setGuest_product
  } = useCart();
  const handleMoveSelectedToCart = () => {
    moveFromWishlistToCart();
  };
  const storedObject = sessionStorage.getItem("user-token");
  const myRetrievedObject = JSON.parse(storedObject);
  
  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [setIsLoggedIn]);

  const handleRedirectBeforeLogin = (targetPage) => {
    // Store the intended target page in sessionStorage
    sessionStorage.setItem("redirectAfterLogin", targetPage);
    navigate("/login");
  };
  const handleLogoClick = () => {
    // Remove the 'redirectAfterLogin' entry from sessionStorage
    sessionStorage.removeItem("redirectAfterLogin");
  };
  
  useEffect(()=>{
    const updateGuestProduct = () => {
      const products = JSON.parse(sessionStorage.getItem("guest_products")) || [];
      setGuest_product(products);
    };
    updateGuestProduct();
    window.addEventListener("storage", updateGuestProduct);
    return () => {
      window.removeEventListener("storage", updateGuestProduct);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handlelogout = () => {
    sessionStorage.removeItem("user-token");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    googleLogout();
    // setIsAuthenticated(false);
    axios
      .delete(
        `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/logout`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        sessionStorage.removeItem("accessToken");
        // console.log("Product removed from cart:");
      })
      .catch((error) => {
        console.error("Error removing product from cart:", error);
      });
    setIsLoggedIn(false);
    if (sessionStorage.getItem("user-token") === null) {
      setCartItems([]);
      calculateTotalPrice();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    setGuest_product(JSON.parse(sessionStorage.getItem("guest_products")) || [])
    axios
      .get(
        `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/selleraccount`
      )
      .then((res) => {
        if (res.data !== "Error" && res.data !== "Fail") {
          res.data.map((item) => {
            if (item.email === user.email) {
              return setSellers(item);
            }
            return null;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/addcart`,{
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data !== "Fail" && response.data !== "Error") {
          if (sessionStorage.getItem("user-token") !== null) {
            sessionStorage.getItem("token") === "user" &&
              setCartItems(
                response.data.filter(
                  (item) =>
                    item.userid.toString() ===
                    sessionStorage.getItem("user-token")
                )
              );
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching cart items:", error);
      });
      axios
      .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/wishlist`)
      .then((response) => {
        if (response.data !== "Fail" && response.data !== "Error") {
              setWishItems(
                response.data.filter(
                  (item) =>
                    item.userid.toString() ===
                    sessionStorage.getItem("user-token")
                )
              );
          }
        
      })
      .catch((error) => {
        console.error("Error fetching wishlist items:", error);
      });
   

    axios
      .get(
        `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/allproducts`
      )
      // console.log(res)
      .then((res) => {
        // console.log(res);
        if (res.data !== "Error" && res.data !== "Fail") {
          const filteredUserDetails = res.data.filter(
            (item) => item.seller_id === myRetrievedObject
          );
          if (filteredUserDetails.length > 0) {
            setData(filteredUserDetails);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/adminproducts`
      )
      .then((res) => {
        if (res.data !== "Fail" && res.data !== "Error") {
          setProducts(
            res.data.filter(
              (item) =>
                item.rejection_reason === null &&
                item.accepted_by_admin === "false"
            )
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const handleHover = () => {
    setIsRotated(!isRotated);
  };
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchTerm); // Call onSearch function with current searchTerm
    }
  };
  const [isHovered, setIsHovered] = useState(false);

  // Example store names; this can be fetched from an API or database
 

  const handleHovers = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const [storeData, setStoreData] = useState([]); 

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/usermanagement`
      )
      .then((res) => {
        if (res.data !== "Error" && res.data !== "Fail") {
          setStoreData(res.data); // Renamed from setData to setStoreData
        }
      });
  }, []);
  const sellersData = useMemo(() => {
    return Array.from(new Set(storeData.map((item) => item.seller_id))).map(
      (sellerId) => {
        const sellerData = storeData.find((item) => item.seller_id === sellerId);
        return {
          seller_id: sellerId,
          displayName:
            sellerData?.shopname ||
            `${sellerData?.firstname} ${sellerData?.lastname}` ||
            "Unnamed Seller",
        };
      }
    );
  }, [storeData]);

  // all sellers
  // const sellersData = useMemo(() => {
  //   // Create a set of unique seller_id's and user_id's
  //   const uniqueSellers = Array.from(new Set(storeData.map((item) => item.seller_id || item.user_id)));
  
  //   return uniqueSellers.map((id) => {
  //     // Find the user data either by seller_id or user_id
  //     const sellerData = storeData.find((item) => item.seller_id === id || item.user_id === id);
  
  //     return {
  //       seller_id: sellerData?.seller_id || id, // If seller_id exists, use it, otherwise use user_id
  //       displayName: 
  //         sellerData?.shopname || 
  //         `${sellerData?.firstname} ${sellerData?.lastname}` ||
  //         "Unnamed Seller", // Display shopname or full name
  //     };
  //   });
  // }, [storeData]);
  
// console.log(sellersData)
  // const handleShopSelect = (sellerId) => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/users`,
  //       { sellerID: sellerId }
  //     )
  //     .then((res) => {
  //       if (res.data !== "Fail" && res.data !== "Error") {
  //         const userDetails = res.data.map((item) => ({
  //           userId: item.user_id,
  //           email: item.email,
  //           phone: item.phone,
  //           name: item.firstname + " " + item.lastname,
  //           shopname: item.shopname,
  //         }));
  //         navigate(`/sellerprofile/${sellerId}`, {
  //           state: { userDetails },
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching seller details:", error);
  //     });
  // };
  return (
    <>
      <div className="gradientnav sticky-top">
        <nav className="navbar navbar-expand-md navbar-light bg-white  d-md-flex  justify-content-around">
          <div className="d-flex">
          {user.email === "admin@admin" ? null :(
            <span
              className="toggle ms-1 mt-2"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <i className="bi bi-justify"></i>
            </span>
            )}
            <div className="ms-lg-5 ms-md-3 ms-1 bargainlogodiv">
              <Link to="/" className=" text-decoration-none" onClick={handleLogoClick}>
                <img
                  src={RBLogo}
                  alt="logo"
                  className="RBlogo"
                  style={{ objectFit: "contain" }}
                />
                {/* <p style={{ color:"#1d1160" , position:"relative" , right:"10px", bottom:"5px" , fontStyle:"italic"}} className="p-0 m-0 logocaption">Your Sustainability closet</p> */}
              </Link>
            </div>
          </div>
          <div
            className=" ms-md-3 Mobilesearchdiv"
            style={{ marginTop: "10px" }}
          >
            <SearchBar
              onSearch={handleSearch}
              handleChange={handleInputChange}
              handleKeyPress={handleKeyPress}
            />
          </div>

          <div className="d-flex me-lg-2 pe-lg-2 authdiv">
            <div className="d-md-flex ">
              <div className="">
              
                    <div className="d-flex">
                      <div
                        className="searchIcon"
                        style={{ marginTop: "20px", marginRight: "8px" }}
                      >
                        <i
                          className="bi bi-search fs-5"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasTop"
                          aria-controls="offcanvasTop"
                        ></i>
                      </div>
                     

                      {isLoggedIn && user.email === "admin@admin" ? null : (
                  <>

                     
                      <div className=" ms-md-1 me-md-2  me-lg-3 ms-2 me-2  cartBtn" title="Cart" style={{marginTop:"20px"}}>
                        <Link
                          to="/cartitems"
                          className="text-decoration-none text-dark"
                          onClick={handleLogoClick}
                        >
                          <i className="bi bi-cart3 fs-5 position-relative">
                            {isLoggedIn ? ( 
                            cartItems.length > 0 && (
                              <span
                                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"
                                style={{ fontSize: "12px" }}
                              >
                                {cartItems.length}
                                <span className="visually-hidden">
                                  unread messages
                                </span>
                              </span>
                            )):(
                              guest_product.length > 0 && (
                                <span
                                  className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"
                                  style={{ fontSize: "12px" }}
                                >
                                  {guest_product.length}
                                  <span className="visually-hidden">
                                    unread messages
                                  </span>
                                </span>)
                            )}
                          </i>
                        </Link>
                      </div>
                     
                      {user.email === "admin@admin" ? null : (
                        <div
                          className=" ms-md-2 me-md-2  me-lg-3 ms-2 me-2  cartBtn"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal2"
                          title="WishList"
                          style={{marginTop:"20px"}}
                        >
                          {" "}
                          <i className="bi bi-heart-fill fs-5 position-relative">
                            {" "}
                            {wishItems.length > 0 && (
                              <span
                                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"
                                style={{ fontSize: "12px" }}
                              >
                                {wishItems.length}
                                <span className="visually-hidden">
                                  unread messages
                                </span>
                              </span>
                            )}
                          </i>
                        </div>
                      )}
                        </>
                    )}
                     {/* <div className=" ms-md-2 me-md-2  me-lg-3 ms-2 me-2  cartBtn" title="Stores" style={{marginTop:"20px"}}>
                        <Link
                          to="/shoplists"
                          className="text-decoration-none text-dark"
                          onClick={handleLogoClick}
                        >
                          <i className="bi bi-shop-window fs-5 position-relative">

                          </i>
                        </Link>
                      </div> */}
                       <div
      className="ms-md-2 me-lg-3 "
      style={{ marginTop: "12px",fontWeight:500,position:'relative'}} 
      onMouseEnter={handleHovers}
      onMouseLeave={handleLeave}
      
    >
      <span
        className="text-dark stores store nav-link-ltr ml-3"
        // onClick={handleLogoClick}
      >
        STORES
      </span>

      {/* Dropdown */}
      {isHovered && (
  <div
    className="dropdown-menu"
    style={{
      position: "absolute",
      top: "100%",
      left: "0",
      display: "block",
      zIndex: 1000,
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
      padding: "10px",
      backgroundColor: "#fff",
      borderRadius: "4px",
      minWidth: "150px",
    }}
  >
    <ul style={{ listStyleType: "none", padding: "0" }}>
      {/* Dynamically render the store links */}
      {sellersData.slice(0, 4).map((store, index) => (
        <li key={index}>
          <Link
            to={`/sellerprofile/${store.seller_id}`}
            className="dropdown-item"
            style={{
              padding: "8px 12px",
              textDecoration: "none",
              display: "block", // Ensure the links take up full width
              backgroundColor: "transparent", // Prevent background color on click
            }}
            onMouseDown={(e) => e.preventDefault()} // Prevent blue background on click
            onMouseEnter={(e) => e.target.style.backgroundColor = "#f0f0f0"} // Hover effect
            onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"} // Revert hover effect
          >
            {store.displayName}
          </Link>
        </li>
      ))}
      {/* "Show More" link */}
      <li>
        <Link
          to="/shoplists"
          className="dropdown-item text-primary"
          style={{
            padding: "8px 12px",
            textDecoration: "none",
            color: "#007bff",
            display: "block",
            backgroundColor: "transparent", // Prevent background color change on click
          }}
          onMouseDown={(e) => e.preventDefault()} // Prevent blue background on click
          onMouseEnter={(e) => e.target.style.backgroundColor = "#f0f0f0"} // Hover effect
          onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"} // Revert hover effect
        >
          Show More
        </Link>
      </li>
    </ul>
  </div>
)}
    </div>
                    </div>
                
              
              </div>
            </div>
            {isLoggedIn && user.email === "admin@admin" ? null : (
            <div className="sellnowdiv" style={{ marginTop:"12px"  }}>
                        <Link
                          to="/addnewproduct"
                          className="text-decoration-none text-dark me-lg-3 lineanimation nav-link-ltr"
                          // onClick={() => sessionStorage.setItem('redirectAfterLogin', '/addnewproduct')}
                          onClick={() => {
                            if (sessionStorage.getItem("token") === null) {
                              handleRedirectBeforeLogin("/addnewproduct");
                            } else {
                              navigate("/addnewproduct"); // If user is logged in, just navigate directly
                            }
                          }}
                          style={{ fontWeight: "500" }}
                        >
                          SELL NOW
                        </Link>
                      </div>
                      )}
            <div className="ms-md-2 me-lg-3" style={{  marginTop:"12px" }}>
                      <Link
                        to="/how-it-works"
                        className="text-decoration-none text-dark howitworks lineanimation"
                        style={{ fontWeight: "500" }}
                        onClick={handleLogoClick}
                      >
                        HOW IT WORKS
                      </Link>
                    </div>
            <div className="d-md-flex ps-2 pe-2 ">
              {isLoggedIn ? (
                <div className="button-group " style={{  marginTop:"18px" }}>
                  <button
                    type="button"
                    className="btn btn-secondary me-2 rounded-circle d-flex align-items-center"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="text-white">
                      <i className="bi bi-person-fill fs-6"></i>
                    </span>
                  </button>

                  <ul className="dropdown-menu dropdown-menu-end p-1 persondropdown">
                    <li className="p-1">
                      <button className="dropdown-item" type="button">
                        Hello, {user.firstname}
                      </button>
                    </li>

                    {user.email === "admin@admin" ? (
                      <li className="p-1">
                        <Link
                          to="/acceptproduct"
                          className="text-decoration-none text-dark ps-3"
                        >
                          <i className="bi bi-file-earmark-person-fill fs-6 ">
                            {" "}</i>
                            Administration
                            {products.length > 0 && (
                              <span className="badge text-bg-success">
                                {products.length}
                                <span className="visually-hidden">
                                  unread messages
                                </span>
                              </span>
                            )}
                          {" "}
                        </Link>
                      </li>
                    ) : null}
                    {user.email !== "admin@admin" ? (
                      <li className="p-1">
                        <Link
                          to="/sellerproducts"
                          className="text-decoration-none text-dark ps-3"
                        >
                          <i className="bi bi-file-earmark-person-fill"></i>{" "}
                          Administration
                        </Link>
                      </li>
                    ) : null}
                    {data.length > 0 && user.email !== "admin@admin" ? (
                      <li className="p-1">
                        <Link
                          to="/myshop"
                          className="text-decoration-none text-dark ps-3"
                        >
                          <i className="bi bi-shop"></i> My Shop
                        </Link>
                      </li>
                    ) : null}

                    <li className="p-1">
                      {user.email !== "admin@admin" ? (
                        <Link
                          to="/customerinfo"
                          className="text-decoration-none text-dark ps-3"
                        >
                          <i className="bi bi-person-fill-gear"></i> My Account
                        </Link>
                      ) : (
                        <Link
                          to="/changepassword"
                          className="text-decoration-none text-dark ps-3"
                        >
                          <i className="bi bi-person-fill-gear"></i> My Account
                        </Link>
                      )}
                    </li>
                    {user.email !== "admin@admin" ? (
                      <>
                        <li className="p-1">
                          <Link
                            to="/offers"
                            className="text-decoration-none text-dark ps-3"
                          >
                            <i className="bi bi-cash-stack"></i> Your Offers
                          </Link>
                        </li>
                        <li className="p-1">
                          <Link
                            to="/contactseller"
                            className="text-decoration-none text-dark ps-3"
                          >
                            <i className="bi bi-person-rolodex"></i> Contact Sellers
                          </Link>
                        </li>
                      </>
                    ) : null}

                    <li className="p-1">
                      <Link
                        to="/login"
                        className="text-decoration-none text-dark ps-3"
                        onClick={handlelogout}
                      >
                        <i className="bi bi-box-arrow-right"></i> Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <>
                  <div className="d-flex gap-2" style={{marginTop:"7px"}}>
                    <div className="mt-1 me-lg-2 signupshow">
                      <Link
                        to="/register"
                        className="text-decoration-none text-dark lineanimation nav-link-ltr"
                        style={{ fontWeight: "500" }}
                      >
                        SIGN UP
                      </Link>
                    </div>
                    <div className="mt-1 logindiv">
                      <Link
                        to="/login"
                        className="text-decoration-none text-dark lineanimation nav-link-ltr"
                        style={{ fontWeight: "500" }}
                        onClick={() => {
                          if (sessionStorage.getItem("token") === null) {
                            handleRedirectBeforeLogin("/"); // Redirect to home if not logged in
                          } else {
                            navigate("/login"); // If already logged in, navigate to login directly
                          }
                        }}
                      >
                        LOGIN
                      </Link>
                    </div>  
                  </div>
                </>
              )}
            </div>
          </div>
        </nav>
        {/*Search Offcanvas start */}
        <div
          className="offcanvas offcanvas-top"
          tabIndex="-1"
          id="offcanvasTop"
          aria-labelledby="offcanvasTopLabel"
        >
          <div className="offcanvas-header">
            <h5 id="offcanvasTopLabel">Search</h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body pb-5">
            <SearchBar
              onSearch={handleSearch}
              handleChange={handleInputChange}
              handleKeyPress={handleKeyPress}
            />
          </div>
        </div>
        {/*Search Offcanvas end */}

        {/* Offcanvas start */}
        <div
          className="offcanvas slide offcanvas-end"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header d-flex justify-content-between">
            <div className="">
              <Link to="/" className="text-decoration-none">
                <img
                  src={RBLogo}
                  alt="logo"
                  width="90px"
                  style={{ objectFit: "contain" }}
                />
                 {/* <p style={{fontSize:"11px", color:"#1d1160" , position:"relative" , right:"10px", bottom:"5px" , fontStyle:"italic"}} className="p-0 m-0">Your Sustainability closet</p> */}
              </Link>
              
            </div>
            <i
              className={`bi bi-x-circle-fill fs-3 btnClose ${
                isRotated ? "rotate" : ""
              }`}
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{ cursor: "pointer" }}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            ></i>
          </div>
          <hr />
          <div className="offcanvas-body">
            <SideOffcanvas isLoggedIn={isLoggedIn} />
          </div>
        </div>
        {/* Offcanvas end */}
      </div>
      {/* wishmodal */}
      <div className="modal" id="myModal2">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body" id="showmod"></div>

            <table className="table table-hover ">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Action</th>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {wishItems.length > 0 ? (
                  wishItems.map((product, index) => (
                    <tr key={index}>
                      <td>
                        <button
                          type="button"
                          className="btn-close w-50"
                          onClick={() => removeFromWishlist(product.id)}
                        ></button>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedWishlistItems.includes(
                            product.product_id
                          )}
                          onChange={() =>
                            handleCheckboxChange(product.product_id)
                          }
                        />
                      </td>
                      <td>
                        <img
                          src={`${JSON.parse(product.image)[0]}`}
                          alt={product.name}
                          style={{ maxWidth: "50px", maxHeight: "80px" }}
                        />
                      </td>
                      <td className="text-secondary">{product.name}</td>
                      <td>
                          {selectedCountry === "INR" ? (
                            <>
                              &#8377; {parseFloat(product.price).toFixed(2)}{" "}
                              {/* INR */}
                            </>
                          ) : (
                            <>
                              &#36; {parseFloat(product.price).toFixed(2)}{" "}
                              {/* USD */}
                            </>
                          )}
                        </td>                    
                        </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      <img
                        src={Wishlistemptyimg}
                        alt="Your Cart is Empty"
                        width="200"
                        height="200"
                        style={{ objectFit: "contain" }}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                className="btn btn-primary"
                onClick={handleMoveSelectedToCart}
                disabled={selectedWishlistItems.length === 0}
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyNavbar;
