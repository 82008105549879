import React from "react";
import MyNavbar from "./navbar";
import Footer from "./footer";
import Scrolltotopbtn from "./Scrolltotopbutton";
import blogBanner from "../images/Theblogpage.png";
import { Link } from "react-router-dom";

export default function Blog() {
  return (
    <div className="fullscreen">
      <style>
        {`
          /* Global styles for typography */
          body {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            color: #000;
          }

          h3, h4 {
            color:rgb(61, 57, 57);
            font-weight: 600;
          }

          h3 {
            font-size: 2rem;
            border-bottom: 2px solid rgb(61, 57, 57);
            padding-bottom: 10px;
            margin-bottom: 20px;
            letter-spacing: 0.5px;
          }

          h4 {
            font-size: 1.5rem;
            color:rgb(61, 57, 57);
            text-transform: uppercase;
            margin-top: 20px;
            letter-spacing: 1px;
          }

          p {
            font-size: 1.1rem;
            line-height: 1.7;
            color: #555;
            margin-bottom: 20px;
          }

          ul, ol {
            list-style-position: inside;
            margin-left: 20px;
          }

          ul li, ol li {
            margin-bottom: 10px;
            font-size: 1rem;
            color: #666;
          }

          /* Plan Section Styles */
          .plan-section {
            background-color: #ffffff;
            padding: 30px;
            border-radius: 8px;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            margin-top: 30px;
            margin-bottom: 30px;
          }

          .plan-section h4 {
            color: #e74c3c;
            font-size: 1.6rem;
            font-weight: 700;
          }

          .plan-section ul {
            list-style: none;
            padding: 0;
          }

          .plan-section ul li {
            font-size: 1.1rem;
            padding-left: 1.5rem;
            position: relative;
          }

          .plan-section ul li::before {
            content: "\\2022";
            position: absolute;
            left: 0;
            top: 0;
            color: #8e44ad;
            font-size: 1.3rem;
          }

          /* Button Styles */
          .btn-primary {
            font-size: 1.1rem;
            font-weight: 500;
            padding: 10px 20px;
            border-radius: 4px;
          }

          /* Responsive design */
          @media (max-width: 768px) {
            .container {
              width: 90%;
            }

            h3 {
              font-size: 1.7rem;
            }

            h4 {
              font-size: 1.3rem;
            }

            p {
              font-size: 1rem;
            }
          }
        `}
      </style>
      <MyNavbar />
      <main>
        <div>
          <img src={blogBanner} alt="Blog Banner" width="100%" height="350" />
        </div>
        <div className="container mt-5 mb-5">
          <div>
            <h3>Where Can I Sell My Clothes Online?</h3>
            <h5>A Guide to Selling New Clothes</h5>
            <p>
              In today’s fast-paced fashion world, sustainability and
              affordability go hand in hand. If you want to sell brand-new
              clothes, online resale platforms provide the perfect opportunity
              to turn your inventory into cash. The Resale Bazaar makes this
              process easy, allowing you to reach the right buyers and grow your
              business.
            </p>
          </div>
          <div>
            <h4>Where Can I Sell My Clothes Online?</h4>
            <p>
              If you’re wondering about the best place to sell your clothes
              online, here are some key factors to consider:
            </p>
            <ul>
              <li>
                {" "}
                Ease of Listing – Platforms should offer a hassle-free way to
                upload your items.
              </li>
              <li>
                Right Audience – Selling where buyers are actively searching for
                your type of clothing increases sales.
              </li>
              <li>
                Affordable Commissions – High fees can cut into your earnings,
                so choosing a cost-effective platform is crucial.
              </li>
              <li>
                Fast & Secure Payments – Ensure that the site provides a smooth
                and secure transaction process.
              </li>
            </ul>
          </div>
          <div>
            <h4>Why Choose The Resale Bazaar?</h4>
            <p>
              At The Resale Bazaar, we offer a seamless selling experience for
              both preloved and brand-new fashion. Here’s why sellers love our
              platform:
            </p>
            <ul>
              <li>
                Simple Selling Process – List your items with ease and reach
                fashion-conscious buyers.
              </li>
              <li>
                Targeted Audience – Our marketplace attracts shoppers looking
                for affordable, high-quality clothing.
              </li>
              <li>
                Fair Pricing & Low Fees – Maximize your earnings with our
                transparent pricing structure.
              </li>
              <li>
                Sustainability Matters – Reselling extends the life of clothing,
                reducing waste and promoting eco-friendly fashion.
              </li>
            </ul>
          </div>
          <div className="plan-section">
            <h4>BASIC PLAN – Start Selling for Free!</h4>
            <p>Our Basic Plan allows you to sell with zero upfront costs:</p>
            <ul>
              <li>Post up to 20 listings for free</li>
              <li>Only 12% commission on sold items</li>
              <li>Secure direct online payment portals</li>
            </ul>
            <p>
              With no hidden charges, you can start selling risk-free and earn
              from your wardrobe!
            </p>
          </div>
          <div>
            <h4>How to Sell New Clothes Online?</h4>
            <p>
              Selling brand-new clothes requires the right approach. Follow
              these steps to ensure a smooth selling experience:
            </p>
            <ol>
              <li>
                <span>Choose the Right Platform</span>
                <br />
                <span>
                  Select a platform where buyers are actively searching for
                  stylish, brand-new fashion. The Resale Bazaar connects you
                  with fashion-conscious shoppers.
                </span>
              </li>
              <li>
                <span>Take High-Quality Photos</span>
                <br />
                <span>
                  Capture your clothing in good lighting to highlight fabric,
                  design details, and overall quality. Buyers love clear and
                  attractive visuals!
                </span>
              </li>
              <li>
                <span>Write an Engaging Description</span>
                <br />
                <span>
                  Mention key details such as size, material, condition, and any
                  unique elements (hand embroidery, designer label, etc.).
                </span>
              </li>
              <li>
                <span>Set a Competitive Price</span>
                <br />
                <span>
                  Research similar listings to price your item fairly. Offering
                  reasonable prices increases your chances of a quick sale.
                </span>
              </li>
              <li>
                <span>Ship Smartly</span>
                <br />
                <span>
                  Ensure you package your items securely for safe delivery.
                  Providing tracking information builds buyer trust.
                </span>
              </li>
            </ol>
          </div>
          <div>
            <p>Start Selling on The Resale Bazaar Today!</p>
            <p>
              Whether you're looking for a trusted place to sell brand-new
              clothes online, The Resale Bazaar is your go-to destination. List
              your items now and turn your inventory into earnings while
              promoting smart fashion choices.
            </p>
            {/* <Link
              className="btn btn-primary"
              to={
                sessionStorage.getItem("token") !== "admin" &&
                (sessionStorage.getItem("token") !== null
                  ? "/addnewproduct"
                  : "/login")
              }
            >
              Start Selling Now
            </Link> */}
          </div>
          <div className="text-end">
            <Link
              className="btn btn-secondary"
              to={
                sessionStorage.getItem("token") !== "admin" ? "/contactus" : "#"
              }
            >
              Contact Us <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
      </main>
      <Footer />
      <Scrolltotopbtn />
    </div>
  );
}
