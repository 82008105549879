import React, { useState } from 'react';

const CountrySelectionModal = ({ onSelectCountry }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleChange = (e) => {
    const newCountry = e.target.value; // Get the selected country value
    setSelectedCountry(newCountry); // Update the state

    // Call the onSelectCountry function after the state update
    onSelectCountry(newCountry);
  };

  return (
    <div className="modal show" style={{ display: 'block' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Please Select Your Country</h5>
          </div>
          <div className="modal-body">
            {/* India radio button */}
            <div>
              <input
                type="radio"
                id="india"
                value="INR"  // This should be 'INR' for India
                name="country"
                checked={selectedCountry === 'INR'} // Check if 'INR' is selected
                onChange={handleChange} // Update state when selected
              />{" "}
              <label htmlFor="india">India</label>
            </div>
            {/* USA radio button */}
            <div>
              <input
                type="radio"
                id="usa"
                value="USD"  // This should be 'USD' for USA
                name="country"
                checked={selectedCountry === 'USD'} // Check if 'USD' is selected
                onChange={handleChange} // Update state when selected
              />{" "}
              <label htmlFor="usa">USA</label>
            </div>
          </div>
          <div className="modal-footer">
            {/* Optional: You can add a button here if you want */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountrySelectionModal;
