import React, { useState, useEffect } from "react";
import MyNavbar from "./navbar";
import { Link } from "react-router-dom";
import Footer from "./footer";
import axios from "axios";
import Scrolltotopbtn from "./Scrolltotopbutton";
import Notification from "./Notification";

export default function Contactus() {
  const [notification, setNotification] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [values, setValues] = useState({
    name: "",
    email: "",
    enquiry: "",
    captchaInput: "",
  });
  const [loading, setLoading] = useState(false); // Track loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    let uniqueCaptcha = "";
    const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 6; i++) {
      uniqueCaptcha += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    setCaptcha(uniqueCaptcha);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (values.captchaInput !== captcha) {
      setCaptchaError("Invalid captcha. Please try again.");
     
      return;
    }
    
    setCaptchaError("");
    setLoading(true);
  
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/contact`,
        values
      );
  
      if (response.status === 200) { // Updated success condition
        setNotification({ message: "Form successfully submitted.", type: "success" });
        setTimeout(() => setNotification(null), 3000);
        
        // Clear form and reset captcha
        setValues({ name: "", email: "", enquiry: "", captchaInput: "" });
        generateCaptcha();
      } else {
        throw new Error("Unexpected response from server");
      }
    } catch (err) {
      console.error(err);
      setNotification({
        message: "Error while submitting form. Please try again.",
        type: "error",
      });
      setTimeout(() => setNotification(null), 3000);
     
    }finally {
      setLoading(false);
    }
  };
  

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/contact`,
  //       values
  //     )
  //     .then((res) => {
  //       if (res.data === "Error") {
  //         setNotification({
  //           message:
  //             "Error while adding product. Please try again filling all the fields",
  //           type: "error",
  //         });
  //         setTimeout(() => setNotification(null), 3000);
  //       } else {
  //         setNotification({
  //           message: "contact information successfully added",
  //           type: "success",
  //         });
  //         setTimeout(() => setNotification(null), 3000);
  //         window.location.reload(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const generateCaptcha = () => {
  //   const captchaElement = document.getElementById("image");
  //   let uniquechar = "";
  //   const randomchar =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   for (let i = 1; i < 7; i++) {
  //     uniquechar += randomchar.charAt(
  //       Math.floor(Math.random() * randomchar.length)
  //     );
  //   }
  //   captchaElement.innerHTML = uniquechar;
  //   setCaptcha(uniquechar);
  // };

  return (
    <div className="fullscreen">
      <MyNavbar />
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <main>
        <img
          src="https://www.lincad.co.uk/wp-content/uploads/2022/08/contact-us-scaled-1.jpg"
          alt="aboutus"
          width="100%"
          height="300px"
          style={{
            objectFit: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></img>
        <div className="d-lg-flex justify-content-around p-2 ps-lg-5 pe-lg-5 mt-3">
          <div className="col-xs-12 col-md-12 col-lg-12 ps-3">
            <h1 style={{ fontSize: "26px" }}>Contact Us</h1>
            <div className="d-lg-flex gap-5">
              <section className="col-lg-8 mt-4">
                <p>
                  Thank you for visiting The Resale Bazaar. For any questions,
                  feedback, and/or grievances please email us at &nbsp;
                  <Link
                    to="mailto:theresalebazaar@gmail.com"
                    className="text-decoration-none"
                  >
                    theresalebazaar@gmail.com
                  </Link>
                  &nbsp;with the necessary information.
                </p>
                <blockquote>
                  <p>
                    {" "}
                    We aim to respond within 24 hours and get back to you with
                    the best possible answers and solutions for your queries. To
                    find answers at the quickest rate, you can also check our
                    Frequently Asked Questions page.
                  </p>
                  <footer className="text-end me-5">
                    - Team The Resale Bazaar
                  </footer>
                </blockquote>
              </section>
              <div className=" p-2">
                <div
                  className="card contactusaddresscard"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="first-card">
                    <h3 className="text-center mt-4">Contact</h3>
                    <div>
                      <p>
                        <span>
                          <i className="bi bi-envelope-at-fill"></i>
                        </span>{" "}
                        theresalebazaar@gmail.com
                      </p>
                      <p>
                        <span>
                          <i className="bi bi-telephone-fill"></i>
                        </span>{" "}
                        (919) 355-8551
                      </p>
                      <p>
                        <span>
                          <i className="bi bi-geo-fill"></i>
                        </span>{" "}
                        639 Berry Chase Way, Cary, NC 27519.
                      </p>
                    </div>
                  </div>

                  <div
                    className={`back-card ${isHovered ? "hovered" : ""}`}
                  ></div>
                  <div
                    className={`thid-card ${isHovered ? "hovered" : ""}`}
                  ></div>
                </div>
              </div>
            </div>
            <form className="m-3" onSubmit={handleSubmit}>
      <div className="d-md-flex col-md-8 col-xs-12 mt-3 mb-3">
        <label htmlFor="name" className="col-md-2">
          <b>Your Name</b>
        </label>
        <div className="d-flex col-md-8">
          <input
            type="text"
            className="col-md-5 form-control"
            id="name"
            name="name"
            placeholder="Enter Your Name"
            onChange={handleChange}
            value={values.name}
            required
          />
          &nbsp;<span className="text-danger fs-4">*</span>
        </div>
      </div>
      <div className="d-md-flex col-md-8 col-xs-12 mt-3 mb-3">
        <label htmlFor="email" className="col-md-2">
          <b>Your Email</b>
        </label>
        <div className="d-flex col-md-8">
          <input
            type="email"
            className="form-control col-md-5"
            id="email"
            name="email"
            placeholder="Enter Your Email Address"
            onChange={handleChange}
            value={values.email}
            required
          />
          &nbsp;<span className="text-danger fs-4">*</span>
        </div>
      </div>
      <div className="d-md-flex col-md-8 col-xs-12 mt-3 mb-3">
        <label htmlFor="enquiry" className="col-md-2">
          <b>Enquiry</b>
        </label>
        <div className="d-flex col-md-8">
          <textarea
            id="enquiry"
            className="col-md-5 form-control"
            name="enquiry"
            rows="5"
            cols="40"
            placeholder="Enter Your Enquiry"
            onChange={handleChange}
            value={values.enquiry}
            required
          ></textarea>
          &nbsp;<span className="text-danger fs-4">*</span>
        </div>
      </div>
      <div className="d-flex col-md-8 col-xs-12 justify-content-center mt-2 mb-2">
        <div id="user-input" className="d-flex m-2 gap-2">
          <input
            type="text"
            className="mr-2 form-control"
            id="submit"
            name="captchaInput"
            placeholder="Enter Captcha"
            value={values.captchaInput}
            onChange={handleChange}
            required
          />
          <div className="mr-2 mt-1" onClick={generateCaptcha}>
            <i className="fas fa-sync"></i>
          </div>
          <div className="bg-secondary text-decoration-line-through fst-italic p-1 rounded fs-5 no-select">
            {captcha}
          </div>
        </div>
      </div>
      {captchaError && <p className="text-danger text-center">{captchaError}</p>}
      {notification && (
        <p className={`text-${notification.type} text-center`}>{notification.message}</p>
      )}
      <div className="d-md-flex col-md-8 col-xs-12 mt-3 mb-3">
        <div className="col-md-2"></div>
        <button type="submit" className="btn btn-primary col-md-3" disabled={loading}>
          {loading  ? (
            <span>
              <span className="spinner-border spinner-border-sm me-2" role="status"></span>
              Submitting...
            </span>
          ) : (
            "SUBMIT"
          )}
        </button>
      </div>
    </form>
          </div>
        </div>
      </main>
      <Footer />
      <Scrolltotopbtn />
    </div>
  );
}
