import "./App.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import { Route, Routes, useNavigate } from "react-router-dom";

import Home from "./components/home";
import Womenallproducts from "./components/women/Womenallproducts";
import Highendcouture from "./components/women/Highendcouture";
import Sarees from "./components/women/Sarees";
import Lehenga from "./components/women/Lehenga";
import Dresses from "./components/women/Dresses";
import Twinningoutfits from "./components/women/Twinningoutfits";

import Kidsallproducts from "./components/kids/kidsallproducts";
import Girl from "./components/kids/Girl";
import Boy from "./components/kids/Boy";

import Jewelryallcollection from "./components/jewelry/Jewelryallcollection";

import Shirts from "./components/men/Shirts";
import Tshirts from "./components/men/Tshirts";
import Jeans from "./components/men/Jeans";
import HoodiesSweatshirts from "./components/men/HoodiesSweatshirts";
import Menallproducts from "./components/men/Menallproducts";
import Kurtas from "./components/men/Kurtas";

import Accessoryallproducts from "./components/accessories/accessoryallproducts";
import Watches from "./components/accessories/Watches";
import Sunglasses from "./components/accessories/Sunglasses";
import Belts from "./components/accessories/Belts";
import Caps from "./components/accessories/Caps";
import Shoes from "./components/accessories/Shoes";

import Login from "./components/login";

import Register from "./components/register";
import Customerinfo from "./components/customerdetails/customerinfo";
import Addresses from "./components/customerdetails/addresses";
import Addaddress from "./components/customerdetails/Addaddress";
import Orders from "./components/customerdetails/Orders";
import Changepassword from "./components/customerdetails/Changepassword";
import Productdetails from "./components/Productdetails";

import Aboutus from "./components/Aboutus";
import Contactus from "./components/Contactus";
import Selleraccount from "./components/Selleraccount";
import FAQ from "./components/Faq";
import Emailverification from "./components/Emailverification";
import ProtectedRoute from "./components/ProtectedRoute";
import Notfound from "./Notfound";
import Addnewproduct from "./components/sellerdashboard/Addnewproduct";
import Shipments from "./components/sellerdashboard/Shipments";
import Sellerproducts from "./components/sellerdashboard/Sellerproducts";
import Acceptproduct from "./components/admindashboard/acceptproduct";
import Checkout from "./components/Checkoutpage";
import Finalcheckoutpage from "./components/finalcheckoutpage";
import Cartitems from "./components/Cartitems";
import Forgotpassword from "./components/Forgotpassword";
import NecklacesChains from "./components/jewelry/NecklacesChains";
import BraceletsBangles from "./components/jewelry/BraceletsBangles";
import Earrings from "./components/jewelry/Earrings";
import Rings from "./components/jewelry/Rings";
import Offers from "./components/sellerdashboard/Offers";
import Search from "./components/Search";
import OrderPage from "./components/customerdetails/OrderPage";
import SellerProfile from "./components/sellerdashboard/SellerProfilePage";

// import axios from "axios";

import ContactSeller from "./components/sellerdashboard/ContactSeller";
import ReviewRatings from "./components/customerdetails/reviewsRatings";

import Scrolltotop from "./components/Scrolltotop";
import CancelOrder from "./components/customerdetails/CancelOrder";
import Refundproducts from "./components/admindashboard/Refundproducts";
import Myshop from "./components/sellerdashboard/Myshop";
import GuestCheckoutpage from "./components/GuestCheckoutpage";
import GuestShippingdetails from "./components/GuestShippingdetails";
import GuestFinalCheckout from "./components/GuestFinalCheckout";
import GuestMailverification from "./components/GuestMailVerification";
import Productmanagement from "./components/admindashboard/Productmanagement";
import Usersmanagement from "./components/admindashboard/Usersmanagement";
import Termsofuse from "./components/Termsofuse";
import Privacypolicy from "./components/Privacypolicy";
import Shoplists from "./components/shoplists";
import Faqsolution from "./components/admindashboard/Faqsolution";
import Users from "./components/admindashboard/Users";
import Sales from "./components/admindashboard/Sales";
import Plans from "./components/plans";
import DynamicMetaTitles from "./components/DynamicMetaTitles";
import LargeInventoryRequest from "./components/admindashboard/largeInventoryRequest";
import Blog from "./components/Blog";
import AddCategories from "./components/admindashboard/AddCategories";
import axios from "axios";
import CountrySelectionModal from "./CountrySelectionModal";
import Cookies from 'js-cookie';

function App() {
  DynamicMetaTitles();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const checkUserToken = () => {
    const userToken = sessionStorage.getItem("user-token");
    if (!userToken || userToken === "undefined") {
      setIsUserLoggedIn(false);
    }
    setIsUserLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, [isUserLoggedIn]);


  const [country, setCountry] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); // Hook to navigate to different pages

  useEffect(() => {
    // Check if country is already selected in cookies
    const savedCountry = Cookies.get('country');
    if (savedCountry) {
      setCountry(savedCountry); // If country is stored in cookies, set it in state
    } else {
      // If no country is selected, fetch country based on IP address
      axios.get('https://api.ipify.org?format=json')
        .then(response => {
          const ip = response.data.ip;
          // Use IP to fetch geolocation
          axios.get(`https://ipapi.co/${ip}/json/`)
            .then(response => {
              const detectedCountry = response.data.country_code;
              if (!savedCountry) {
                setShowModal(true); // Show the country selection modal
              }
              setCountry(detectedCountry); // Set the detected country in state
              Cookies.set('country', detectedCountry, { expires: 7, path: '' }); // Save selected country in cookie (expires in 7 days)
            })
            .catch(error => {
              console.error("Error fetching geolocation:", error);
            });
        })
        .catch((error) => {
          console.error("Error detecting IP address:", error);
        });
    }
  }, []);
  // Handle country selection
  const handleCountrySelect = (selectedCountry) => {
    setCountry(selectedCountry);
    
    // Save selected country in a cookie
    Cookies.set('country', selectedCountry, { expires: 7, path: '' }); // Cookie will expire in 7 days
    
    setShowModal(false); // Close the modal
    navigate('/'); // Navigate to Home page
    window.location.reload(false)
  };


  return (
    <>
      {/* <BrowserRouter> */}
        <Scrolltotop />
        {/* <React.Suspense fallback={<div>Loading...</div>}> */}
        <Routes>
          {/* Login routes */}
          {/* <Route path="register" element={<Register />}></Route> */}
          <Route path="register" element={<Register />}></Route>
          {/* <Route path="login" element={<Login />}></Route> */}

          <Route path="login" element={<Login />}></Route>
          <Route path="how-it-works" element={<Plans />}></Route>

          <Route
            path="customerinfo"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Customerinfo />}
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="addresses"
            element={
              <ProtectedRoute>{isUserLoggedIn && <Addresses />}</ProtectedRoute>
            }
          ></Route>

          <Route
            path="acceptproduct"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Acceptproduct />}
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="productmanagement"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Productmanagement />}
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="usersmanagement"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Usersmanagement />}
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="users"
            element={
              <ProtectedRoute>{isUserLoggedIn && <Users />}</ProtectedRoute>
            }
          ></Route>
          <Route
            path="sales"
            element={
              <ProtectedRoute>{isUserLoggedIn && <Sales />}</ProtectedRoute>
            }
          ></Route>

          <Route path="checkoutpage" element={<Checkout />}></Route>

          <Route
            path="emailverification"
            element={<Emailverification />}
          ></Route>

          <Route path="cartitems" element={<Cartitems />}></Route>

          <Route
            path="addaddress"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Addaddress />}
              </ProtectedRoute>
            }
          />

          <Route
            path="orders"
            element={
              <ProtectedRoute>{isUserLoggedIn && <Orders />}</ProtectedRoute>
            }
          />

          <Route
            path="changepassword"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Changepassword />}
              </ProtectedRoute>
            }
          />
          <Route path="offers" element={<Offers />}></Route>
          <Route path="aboutus" element={<Aboutus />}></Route>
          <Route path="contactus" element={<Contactus />}></Route>
          <Route
            path="selleraccount"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Selleraccount />}
              </ProtectedRoute>
            }
          ></Route>
          <Route path="faq" element={<FAQ />}></Route>
          <Route path="blog" element={<Blog/>}></Route>

          {/* Women components routes */}
          <Route path="/" element={<Home  country={country}/>}></Route>

          <Route path="women" element={<Womenallproducts />}></Route>
          <Route path="product/:id" element={<Productdetails />}></Route>
          <Route path="High End Couture" element={<Highendcouture />}></Route>
          <Route path="sarees" element={<Sarees />}></Route>
          <Route path="lehenga" element={<Lehenga />}></Route>
          <Route path="dresses" element={<Dresses />}></Route>
          <Route
            path="Twinning-outfits,Tie Dye"
            element={<Twinningoutfits />}
          ></Route>

          {/* Kids components routes */}
          <Route path="kids" element={<Kidsallproducts />}></Route>
          <Route path="girl" element={<Girl />}></Route>
          <Route path="boy" element={<Boy />}></Route>

          {/* Jewelery components routes */}
          <Route path="jewellery" element={<Jewelryallcollection />}></Route>
          <Route path="necklaces" element={<NecklacesChains />}></Route>
          <Route path="bangles" element={<BraceletsBangles />}></Route>
          <Route path="earrings" element={<Earrings />}></Route>
          <Route path="rings" element={<Rings />}></Route>

          {/* Men components routes */}
          <Route path="men" element={<Menallproducts />}></Route>
          <Route path="shirts" element={<Shirts />}></Route>
          <Route path="jeans" element={<Jeans />}></Route>
          <Route path="tshirts" element={<Tshirts />}></Route>
          <Route path="sweatshirts" element={<HoodiesSweatshirts />}></Route>
          <Route path="kurtas" element={<Kurtas />}></Route>

          {/* Accessories components routes */}
          <Route path="accessories" element={<Accessoryallproducts />}></Route>
          <Route path="caps" element={<Caps />}></Route>
          <Route path="belts" element={<Belts />}></Route>
          <Route path="watches" element={<Watches />}></Route>
          <Route path="shoes" element={<Shoes />}></Route>
          <Route path="sunglasses" element={<Sunglasses />}></Route>

          {/* Seller account routes */}
          <Route
            path="addnewproduct"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Addnewproduct />}
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="shipments"
            element={
              <ProtectedRoute>{isUserLoggedIn && <Shipments />}</ProtectedRoute>
            }
          ></Route>

          <Route
            path="sellerproducts"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Sellerproducts />}
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="myshop"
            element={
              <ProtectedRoute>{isUserLoggedIn && <Myshop />}</ProtectedRoute>
            }
          ></Route>

          <Route
            path="finalcheckoutpage"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <Finalcheckoutpage />}
              </ProtectedRoute>
            }
          ></Route>
          <Route path="forgotpassword" element={<Forgotpassword />}></Route>
          <Route path="*" element={<Notfound />}></Route>
          <Route path="/search" element={<Search />} />
          <Route
            path="/orderpage"
            element={
              <ProtectedRoute>{isUserLoggedIn && <OrderPage />}</ProtectedRoute>
            }
          />
          <Route
            path="/cancelorder"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <CancelOrder />}
              </ProtectedRoute>
            }
          />

          <Route path="/sellerprofile/:sellerId" element={<SellerProfile />} />
          <Route
            path="/contactseller"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <ContactSeller />}
              </ProtectedRoute>
            }
          />
          <Route
            path="/feedback"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <ReviewRatings />}
              </ProtectedRoute>
            }
          />
          <Route
            path="/largeinventoryrequest"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <LargeInventoryRequest />}
              </ProtectedRoute>
            }
          />
          <Route
            path="/addcategories"
            element={
              <ProtectedRoute>
                {isUserLoggedIn && <AddCategories />}
              </ProtectedRoute>
            }
          />
          <Route path="refundsproduct" element={<Refundproducts />} />
          <Route path="guestcheckout" element={<GuestCheckoutpage />} />
          <Route path="guestshipping" element={<GuestShippingdetails />} />
          <Route path="guestfinalcheckout" element={<GuestFinalCheckout />} />
          <Route
            path="guestmailverification"
            element={<GuestMailverification />}
          />

          <Route path="termsofuse" element={<Termsofuse />} />
          <Route path="privacypolicy" element={<Privacypolicy />} />
          <Route path="/shoplists" element={<Shoplists />} />
          <Route path="/faqsolution" element={<Faqsolution />} />
        </Routes>
        {/* </React.Suspense> */}
      {/* </BrowserRouter> */}
      {showModal && <CountrySelectionModal onSelectCountry={handleCountrySelect} />}

    </>
  );
}

export default App;
