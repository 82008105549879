import React from "react";
import { Link } from "react-router-dom";

const SideOffcanvas = ({isLoggedIn}) => {
  const handleRedirectBeforeLogin = (targetPage) => {
    // Store the intended target page in sessionStorage
    sessionStorage.setItem("redirectAfterLogin", targetPage);
  };
  return (
    <>
      <div>
        <Link
          to={
            sessionStorage.getItem("token") !== null
              ? "/addnewproduct"
              : "/login"
          }

          className="text-decoration-none btn btn-secondary w-100 m-1"
          style={{ fontWeight: "500" }}
          onClick={() => {
            if (sessionStorage.getItem("token") === null) {
              handleRedirectBeforeLogin("/addnewproduct");
            }
          }}
        >
          Sell now
        </Link>
        {isLoggedIn ?( 
         null
        ):( <>          
        <Link
          to="/register"
          className="text-decoration-none btn btn-secondary w-100 m-1"
          style={{ fontWeight: "500" }}
        >
          Sign up
        </Link>
        <Link
          to="/login"
          className="text-decoration-none btn btn-secondary w-100 m-1"
          style={{ fontWeight: "500" }}
          onClick={() => {
            if (sessionStorage.getItem("token") === null) {
              handleRedirectBeforeLogin("/");
            }
          }}
        >
          Log in
        </Link>
        </>
) }
       
      </div>
    </>
  );
};

export default SideOffcanvas;
