import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyNavbar from "../navbar";
import Menu from "../menu";
import Filterdisplaynav from "../filterdisplaynav";
import Filter from "./filter";
import Product from "../Product";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
// import Footer from "../footer";
import Scrolltotopbtn from "../Scrolltotopbutton";
import Comingsoon from "../comingsoon";
import Shirtsimg from "../../images/shirt.png";
import Jeansimg from "../../images/jeans.jpg";
import Tshirtsimg from "../../images/Tshirts.jpg";
import Hoodiesimg from "../../images/hoodies.jpg";
import Kurtasimg from "../../images/kurtas.png";

export default function Menallproducts() {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const pageSize = 8;
  
    useEffect(() => {
      fetchProducts(page);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
  
    const fetchProducts = async (pageNum) => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/menall?limit=${pageSize}&page=${pageNum}&category=men`
        );
  
        if (res.data !== "Fail" && res.data !== "Error") {
          const filterProducts = res.data;
  
          const existingProductIds = new Set(
            products.map((product) => product.id)
          );
          const newProducts = filterProducts.filter(
            (product) =>
              !existingProductIds.has(product.id) && product.quantity > 0
          );
  
          if (newProducts.length > 0) {
            setProducts((prevProducts) => [...prevProducts, ...newProducts]);
            setFilteredProducts((prevProducts) => [
              ...prevProducts,
              ...newProducts,
            ]);
          }
  
          if (filterProducts.length < pageSize) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      } catch (err) {
        console.log(err);
        setHasMore(false);
      }
    };
  
    const handleFilter = (filteredProducts) => {
      setFilteredProducts(filteredProducts);
    };
  return (
    <div className="fullscreen">
    <MyNavbar />
    <main>
      <h1
        className="ps-lg-5 ps-2 text-center mt-2"
        style={{ textShadow: "2px 3px 2px lightgray", fontSize: "28px" }}
      >
        Mens Fashion
      </h1>
      <div className="scroll-container">
        <div className="m-md-4 m-2">
          <Link
            to="/shirts"
            className="text-dark text-decoration-none"
          >
            <img
              src={Shirtsimg}
              alt="shirts"
              className="rounded-circle womenallimgs"
            />
            <p>Shirts</p>
          </Link>
        </div>
        <div className="m-md-4 m-2">
          <Link to="/jeans" className="text-dark text-decoration-none">
            <img
              src={Jeansimg}
              alt="jeans"
              className="rounded-circle womenallimgs"
            />
            <p>Jeans</p>
          </Link>
        </div>
        <div className="m-md-4 m-2">
          <Link to="/tshirts" className="text-dark text-decoration-none">
            <img
              src={Tshirtsimg}
              alt="tshirts"
              className="rounded-circle womenallimgs"
            />
            <p>T-Shirts</p>
          </Link>
        </div>
        <div className="m-md-4 m-2">
          <Link to="/sweatshirts" className="text-dark text-decoration-none">
            <img
              src={Hoodiesimg}
              alt="sweatshirts"
              className="rounded-circle womenallimgs"
            />
            <p>Hoodies / Sweatshirts</p>
          </Link>
        </div>
        <div className="m-md-4 m-2">
          <Link
            to="/kurtas"
            className="text-dark text-decoration-none"
          >
            <img
              src={Kurtasimg}
              alt="kurtas"
              className="rounded-circle womenallimgs"
            />
            <p>Kurta</p>
          </Link>
        </div>
      </div>
      <nav className="p-2 ps-lg-5 pe-lg-5">
        <Link to="/" className="text-decoration-none text-dark">
          <i className="bi bi-house-fill"></i>
        </Link>
        &nbsp; / Men
      </nav>
      <div className="d-lg-flex justify-content-around p-2 ps-lg-5 pe-lg-5">
        <div className="col-lg-2 col-xs-12 col-md-12">
          <Menu />
          <Filter products={products} onFilter={handleFilter} />
        </div>

        <div className="col-xs-12 col-md-12 col-lg-10 ps-lg-3">
          <Filterdisplaynav
            pageSize={pageSize}
            setPageSize={() => {}}
            productName="Mens Fashion"
          />

          <InfiniteScroll
            dataLength={filteredProducts.length}
            next={() => setPage((prevPage) => prevPage + 1)}
            hasMore={hasMore}
            loader={
              <div className="centered-message">
                <i className="bi bi-arrow-clockwise spin-icon"></i>
              </div>
            }
            endMessage={
              <div className="centered-message">
                
              </div>
            }
          >
            <div className={filteredProducts.length > 0 ? "product-grid container" : "full-page-center"}>
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product, index) => (
                  <Product product={product} key={index} admin="men" />
                ))
              ) : (
                <Comingsoon/>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </main>
    {/* <Footer /> */}
    <Scrolltotopbtn />
  </div>
  )
}
