// utils/priceConversion.js

export const convertPrice = (price, currency, selectedCountry) => {
  const USD_TO_INR = process.env.REACT_APP_DOLLAR_PRICE;
  const INR_TO_USD = 1 / USD_TO_INR;

  if (selectedCountry === "INR") {
    if (currency === "USD") {
      return (price * USD_TO_INR).toFixed(2); 
    }
    return price;// Already in INR
  } else if (selectedCountry === "USD") {
    if (currency === "INR") {
      return (price * INR_TO_USD).toFixed(2); // Convert to USD
    }
    return price; // Already in USD
  }
};
