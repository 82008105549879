import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function DynamicMetaTitles() {
    const location = useLocation();

    useEffect(() => {
        // Define your title and description based on the route
        const metaTags = {
            '/': {
                title: 'Buy & Sell Indian Sarees & Designer Clothes Online in the USA | The Resale Bazaar',
                description: 'Discover the best place to sell used clothes online and shop for Indian designer clothes & sarees in the USA. Buy and sell pre-loved fashion while embracing sustainable shopping.'
            },
            '/aboutus': {
                title: 'About The Resale Bazaar | Sustainable Fashion & Pre-Loved Clothing',
                description: 'Learn about The Resale Bazaar—your go-to platform for selling used clothes online and shopping for Indian sarees & designer outfits in the USA while promoting sustainability.'
            },
            '/contactus': {
                title: 'Contact The Resale Bazaar | Get in Touch for Buying & Selling Clothes',
                description: 'Have questions about how to sell sarees in the USA or buy pre-loved fashion? Contact The Resale Bazaar today for support on selling used clothes online hassle-free!'
            },
            '/faq': {
                title: 'FAQs | The Resale Bazaar | How to Sell & Buy Pre-Loved Clothes',
                description: 'Find answers to your questions about selling used clothes online, where to buy Indian sarees in the USA, and how The Resale Bazaar makes sustainable fashion easy for all.'
            },
            '/how-it-works': {
                title: 'How It Works | Sell & Buy Indian Clothes Online | The Resale Bazaar',
                description: 'Learn how to sell sarees in the USA and buy Indian designer clothes online at The Resale Bazaar. A simple, secure platform for sustainable fashion resale!'
            },
            '/login': {
                title: 'Login to Your Account - The Resale Bazaar',
                description: 'Access your account to manage your purchases, track orders, and explore exclusive deals on The Resale Bazaar.'
            },
            '/register': {
                title: 'Create Your Account - The Resale Bazaar',
                description: 'Sign up to start buying and selling on The Resale Bazaar. Join today to unlock exclusive deals and offers.'
            },
            '/cartitems': {
                title: 'Your Cart - The Resale Bazaar',
                description: 'Review the items in your cart before completing your purchase. Get the best deals on pre-loved products at The Resale Bazaar.'
            },

            '/shoplists': {
                title: 'Explore Stores - The Resale Bazaar',
                description: 'Browse a variety of stores on The Resale Bazaar. Find unique products, limited edition items, and great deals from trusted sellers.'
            },
            '/addnewproduct': {
                title: 'Sell Your Items - The Resale Bazaar',
                description: 'Turn your pre-loved items into cash! List your products and start selling to a wide audience on The Resale Bazaar today.'
            },
            '/customerinfo': {
                title: 'Your Information - The Resale Bazaar',
                description: 'Update your personal details for a smoother shopping experience.'
            },
            '/addresses': {
                title: 'Your Addresses - The Resale Bazaar',
                description: 'Manage and update your shipping addresses.'
            },
            '/orders': {
                title: 'Your Orders - The Resale Bazaar',
                description: 'View, cancel, rate, and review your past orders for a better shopping experience.'
            },
            '/changepassword': {
                title: 'Change Password - The Resale Bazaar',
                description: 'Update your password for account security.'
            },
            '/termsofuse': {
                title: 'Terms of Use - The Resale Bazaar',
                description: 'Read the terms and conditions that govern your use of The Resale Bazaar. Understand your rights and responsibilities while using our platform.'
            },
            '/privacypolicy': {
                title: 'Privacy Policy - The Resale Bazaar',
                description: 'Learn how we collect, use, and protect your personal data when you shop or interact with The Resale Bazaar. Your privacy is important to us.'
            },
            '/kids': {
                title: 'Kids Clothing - The Resale Bazaar',
                description: 'Shop pre-loved clothing for boys and girls, from casual wear to special occasion outfits.'
            },
            '/women': {
                title: 'Women’s Fashion - The Resale Bazaar',
                description: 'Explore stunning sarees, lehengas, high-end couture, and dresses for every occasion.'
            },
            '/jewellery': {
                title: 'jewellery Collection - The Resale Bazaar',
                description: 'Discover a beautiful collection of necklaces, bangles, rings, and earrings to complement any style.'
            },

            default: {
                title: 'The Resale Bazaar - Sustainable Fashion',
                description: 'Resale Bazaar emerged from the desire to breathe new life into unused clothing, fostering sustainability in the process.'
            }
        };

        // Set the meta title and description
        const { title, description } = metaTags[location.pathname] || metaTags.default;

        document.title = title;

        // Update the meta description tag
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', description);
        } else {
            const newMetaDescriptionTag = document.createElement('meta');
            newMetaDescriptionTag.name = 'description';
            newMetaDescriptionTag.content = description;
            document.head.appendChild(newMetaDescriptionTag);
        }
    }, [location]);
}

export default DynamicMetaTitles;
