import React, { useState } from "react";
import axios from "axios";  // You need to install axios via npm
import Adminnavbar from "./Adminnavbar";
import Footer from "../footer";
import Scrolltotopbtn from "../Scrolltotopbutton";
import Notification from "../Notification";

const AddCategories = () => {
  const [productType, setProductType] = useState("");
  const [category, setCategory] = useState("");
   const [notification, setNotification] = useState(null);
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/addProductTypeAndCategory`,
        { producttype: productType.toLowerCase(), category }
      );

      if (response.data.success) {
        setNotification({
          message: "Product type and category added successfully!",
          type: "success",
        });
        setTimeout(() => setNotification(null), 3000);
        setProductType("");
        setCategory("");
      } else {
        setNotification({
          message: "Error adding product type and category.",
          type: "error",
        });
        setTimeout(() => setNotification(null), 3000);
      }
    } catch (error) {
      setNotification({
        message: "There was an error with the request.",
        type: "error",
      });
      setTimeout(() => setNotification(null), 3000);
    }
    
  };

  return (
    <div className="fullscreen">
      <Adminnavbar />
      {notification && (
                <Notification
                  message={notification.message}
                  type={notification.type}
                  onClose={() => setNotification(null)}
                />
              )}
      <div className="container my-5">
        <div className="fullscreen2">
            <h2 className="mb-4 text-center">Product Type and Category</h2>
            <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit} className="row g-3">
              <div className="col-12">
                <label className="form-label">Product Type:</label>
                <input
                  type="text"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  placeholder="Enter product type (e.g., 'women', 'kids')"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-12">
                <label className="form-label">Category:</label>
                <input
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  placeholder="Enter category (e.g., 'Lehenga', 'Sarees')"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary">
                  Add Category
                </button>
              </div>
            </form>
            </div>
          </div>
      </div>
      <Footer />
      <Scrolltotopbtn />
    </div>
  );
};

export default AddCategories;
