import React, { useEffect, useState } from "react";
import MyNavbar from "./navbar";
import Footer from "./footer";
import Curosel from "./curosal";
// import CarouselComponent from "./carouselcomponent";
import axios from "axios";
import "react-multi-carousel/lib/styles.css";
import Product from "./Product";
import { Link } from "react-router-dom";
import Scrolltotopbtn from "./Scrolltotopbutton";
import ba2 from "../images/ba2.jpeg"
import { useCart,useData } from "./CartContext";
// import Marquee from "./Marquee";
import Cookies from 'js-cookie';

const Home = () => {
  // eslint-disable-next-line no-unused-vars
  const [country, setCountry] = useState(Cookies.get('country') || ''); // Default to 'IN' or 'US'
    // eslint-disable-next-line no-unused-vars
  const [allProducts, setAllProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const { user } = useData();

  const {
    isLoggedIn,
    setIsLoggedIn
  } = useCart();
  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [setIsLoggedIn]);


  useEffect(() => {
    const storedCountry = Cookies.get('country');
    if (storedCountry) {
      setCountry(storedCountry);
    }
  }, []);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
    Cookies.set('country', selectedCountry, { expires: 7, path: '' }); // Cookie will expire in 7 days
    window.location.reload(); // Refresh the page to apply the change
  };

  const handleRedirectBeforeLogin = (targetPage) => {
    // Store the intended target page in sessionStorage
    sessionStorage.setItem("redirectAfterLogin", targetPage);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/allproducts`)
      .then((res) => {
        if (res.data !== "Fail" && res.data !== "Error") {
          setAllProducts(res.data);
          setDisplayedProducts(res.data); // Initially display first batch
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);

  const filterAndSliceProducts = (type) => {
    const filteredProducts = displayedProducts
      .filter(item => item.product_type === type && item.quantity > 0)
      .reverse();
    const screenWidth = window.innerWidth;
  
    if (screenWidth >= 992) {
      return filteredProducts.slice(0, 8);
    } else {
      return filteredProducts.slice(0, 6);
    }
  };
  
  

  const womenProducts = filterAndSliceProducts("women");
  const kidsProducts = filterAndSliceProducts("kids");
  const jewelleryProducts = filterAndSliceProducts("jewellery");
  const menProducts = filterAndSliceProducts("men");
  const accessoryProducts = filterAndSliceProducts("accessories");

  return (
    <div className="fullscreen">
      <MyNavbar />
      <main>
        {/* <CarouselComponent /> */}
          {/* <Marquee/> */}
        {/* <h1 className="container mt-3" style={{ fontSize: "28px" }}>Product Categories</h1>
        <Curosel /> */}
        {/* <h2 className="container text-center" style={{ fontSize: "28px" }}>Featured Products</h2> */}
        <div
  // style={{ backgroundImage: `url(${ba2})`, height: "120px", width: "100%" }}
  className="container mt-3 "
>
<div className="d-flex justify-content-end mb-3 gap-3">
        <div className="form-check">
          <input 
            className="form-check-input" 
            type="radio" 
            name="country" 
            id="india" 
            value="INR" 
            checked={country === 'INR'} 
            onChange={handleCountryChange}
          />
          <label className="form-check-label fw-bold" htmlFor="india">
            India
          </label>
        </div>
        <div className="form-check">
          <input 
            className="form-check-input" 
            type="radio" 
            name="country" 
            id="usa" 
            value="USD" 
            checked={country === 'USD'} 
            onChange={handleCountryChange}
          />
          <label className="form-check-label fw-bold" htmlFor="usa">
            USA
          </label>
        </div>
      </div>



  <img src={ba2} alt="banner" width="100%" className="rounded"/>
  {/* <h2
    style={{
      fontSize: "28px",
      fontWeight: "600", // Adds a little more weight to the font
      color: "#fff", // Text color white
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Adds a shadow effect to the text
      textAlign: "center", // Center the text horizontally
      margin: 0, // Remove any default margin
    }}
  >
    Buy and Sell Quality Indian Clothes at The Resale Bazaar
  </h2> */}
</div>
{isLoggedIn && user.email === "admin@admin" ? null : (
  <>
    <div className="text-center mt-5" style={{ fontSize: "20px" }}>
    <Link
  to="/addnewproduct"
  className="text-decoration-none becomeaseller"
  // onClick={() => sessionStorage.setItem('redirectAfterLogin', '/addnewproduct')}
  onClick={() => {
    if (sessionStorage.getItem("token") === null) {
      handleRedirectBeforeLogin("/addnewproduct");
    }
  }}
  style={{ fontWeight: "500" }}
>
  Click here to become a Seller
</Link>
    </div>
  </>
)}

{/* <p className="text-center mt-5" style={{fontSize:"20px"}}>Click here to become a Seller</p> */}

        {/* Womens Fashion */}
        {womenProducts.length > 0 && (
          <>
            <div className="container d-flex justify-content-between align-items-center mt-5 mb-5 bg-light p-2">
              <h4 className="">Womens Fashion</h4>
              <Link to="/women" className="text-decoration-none pe-md-2" style={{fontSize:"18px"}}>
                See all
              </Link>
             
            </div>
            <div className="product-grid container">
              {womenProducts.map((product, index) => (
                <Product key={index} product={product} admin="home" />
              ))}
            </div>
            <div className=" container d-flex justify-content-end" style={{ padding: "5px 20px 10px 10px" }}>
              <Link to="/women" className="text-decoration-none" style={{fontSize:"18px"}}>
                See all
              </Link>
            </div>
          </>
        )}

{menProducts.length > 0 && (
          <>
            <div className="container d-flex justify-content-between align-items-center mt-5 mb-5 bg-light p-2">
              <h4 className="">Mens Fashion</h4>
              <Link to="/men" className="text-decoration-none pe-md-2" style={{fontSize:"18px"}}>
                See all
              </Link>
             
            </div>
            <div className="product-grid container">
              {menProducts.map((product, index) => (
                <Product key={index} product={product} admin="home" />
              ))}
            </div>
            <div className=" container d-flex justify-content-end" style={{ padding: "5px 20px 10px 10px" }}>
              <Link to="/men" className="text-decoration-none" style={{fontSize:"18px"}}>
                See all
              </Link>
            </div>
          </>
        )}

        {/* Kids Fashion */}
        {kidsProducts.length > 0 && (
          <>
            <div className="container d-flex justify-content-between align-items-center mt-5 mb-5 bg-light p-2">
              <h4 className="">Kids Fashion</h4>
              <Link to="/kids" className="text-decoration-none pe-md-2" style={{fontSize:"18px"}}>
                See all
              </Link>
            </div>
            <div className="product-grid container">
              {kidsProducts.map((product, index) => (
                <Product key={index} product={product} admin="home" />
              ))}
            </div>
            <div className=" container d-flex justify-content-end" style={{ padding: "5px 20px 10px 10px" }}>
              <Link to="/kids" className="text-decoration-none" style={{fontSize:"18px"}}>
                See all
              </Link>
            </div>
          </>
        )}

        {/* Jewellery Collection */}
        {jewelleryProducts.length > 0 && (
          <>
            <div className="container d-flex justify-content-between align-items-center mt-5 mb-5 bg-light p-2">
              <h4 className="">Jewellery</h4>
              <Link to="/jewellery" className="text-decoration-none pe-md-2" style={{fontSize:"18px"}}>
                See all
              </Link>
            </div>
            <div className="product-grid container ">
              {jewelleryProducts.map((product, index) => (
                <Product key={index} product={product} admin="home" />
              ))}
            </div>
            <div className="container d-flex justify-content-end mb-5" style={{ padding: "5px 20px 10px 10px" }}>
              <Link to="/jewellery" className="text-decoration-none" style={{fontSize:"18px"}}>
                See all
              </Link>
            </div>
          </>
        )}

{accessoryProducts.length > 0 && (
          <>
            <div className="container d-flex justify-content-between align-items-center mt-5 mb-5 bg-light p-2">
              <h4 className="">Accessories</h4>
              <Link to="/accessories" className="text-decoration-none pe-md-2" style={{fontSize:"18px"}}>
                See all
              </Link>
             
            </div>
            <div className="product-grid container">
              {accessoryProducts.map((product, index) => (
                <Product key={index} product={product} admin="home" />
              ))}
            </div>
            <div className=" container d-flex justify-content-end" style={{ padding: "5px 20px 10px 10px" }}>
              <Link to="/accessories" className="text-decoration-none" style={{fontSize:"18px"}}>
                See all
              </Link>
            </div>
          </>
        )}

<h1 className="container mt-3" style={{ fontSize: "28px" }}>Product Categories</h1>
<Curosel />

      </main>
      <Footer />
      <Scrolltotopbtn />
    </div>
  );
};

export default Home;
